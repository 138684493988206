<template>
  <div style="background: #3F51B5; height: 70px; margin-top: 50px">
             <h2 class="white--text pt-4 ml-12 mr-12"  >
               {{ new Date().getFullYear() }} — <strong>Mesanalyses.dz</strong>
          </h2>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>